.bl {
  border-left: 1px solid #e9e9e9;
}

.br {
  border-right: 1px solid #e9e9e9;
}

.br-5 {
  border-radius: 5px;
}

.br-14 {
  border-radius: 14px;
}
