.weight {
  width: 50px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}
.weight2500 {
  height: 500px;
  background-color: #ff0000;
  line-height: 500px;
}

.weight2000 {
  height: 500px;
  background-color: #0000ff;
  line-height: 500px;
}

.weight1500 {
  height: 450px;
  background-color: #eeee00;
  line-height: 450px;
}

.weight1000 {
  height: 400px;
  background-color: #0abb00;
  line-height: 400px;
}

.weight500 {
  height: 350px;
  background-color: #000000;
  color: #ffffff;
  line-height: 350px;
}

.weight250 {
  width: 50px;
  background-color: #000000;
  color: #ffffff;
  line-height: 300px;
}

.weight125 {
  height: 250px;
  background-color: #000000;
  color: #ffffff;
  line-height: 250px;
}

.weight50 {
  height: 200px;
  background-color: #000000;
  color: #ffffff;
  line-height: 200px;
}

.weight25 {
  height: 150px;
  background-color: #000000;
  color: #ffffff;
  line-height: 150px;
}

.weightClose {
  position: relative;
  top: 25px;
  height: 50px;
  width: 100px;
  margin-right: 0;
  background-color: #aaaaaa;
}

.weightBar {
  position: relative;
  top: 10px;
  height: 20px;
  width: 15px;
  margin-right: 0;
  background-color: #000000;
}

.weightBar:last-child {
  width: 50px;
}
