.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

.text-center {
  text-align: center;
}
