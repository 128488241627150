.ant-table-thead > tr > th {
  vertical-align: bottom;
}

.ant-table-thead > tr > th.discipline-single-header {
  border-bottom: 0;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.ant-table-small .ant-table-thead > tr > th.discipline-total,
.ant-table-small .ant-table-tbody > tr > td.discipline-total {
  border-right-color: #c6c6c6;
  border-right-width: 1px;
  border-right-style: solid;
  border-left-color: #c6c6c6;
  border-left-width: 1px;
  border-left-style: solid;
}

.ant-table-title {
  background-color: #c6c6c6;
}
